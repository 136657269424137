import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContentContainer from "../components/Core/Container/ContentContainer"

const TextContent = styled.div`
  margin: 95px auto 233px;
  max-width: 881px;

  h1 {
    ${fonts.canelaThin};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 1;
    margin-bottom: 61px;
  }

  h2 {
    ${fonts.labGrotesqueBold};
    font-size: 19px;
    line-height: normal;
    letter-spacing: 3.8px;
    text-transform: uppercase;
    margin-bottom: 29px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 29px;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }
  }

  ul {
    li {
      list-style: disc;
      margin-left: 20px;
      margin-bottom: 29px;
    }
  }

  a {
    color: ${colors.grey};
  }

  @media (min-width: ${breakpoints.md}) {
    margin: 159px auto 197px;
    h1 {
      margin-bottom: 111px;
    }
    h2 {
      margin-top: 60px;
      :first-of-type {
        margin-top: 0;
      }
    }
  }
`

const TextPageTemplate = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page } = data
  const { metaTitle, metaDescription, content } = page

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <ContentContainer>
        <TextContent>{renderRichText(content)}</TextContent>
      </ContentContainer>
    </Layout>
  )
}

export default TextPageTemplate

export const pageQuery = graphql`
  query TextPageQuery($slug:String!) {
    page: contentfulTextPage(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      content {
        raw
      }
    }
  }
`
